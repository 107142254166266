import { RouteConfig } from 'vue-router'

export const signInRoutePaths = {
    AUTH_LINK: `/a/:token`,
    SIGN_IN_PASSWORD: `/signIn/password`,
    SIGN_IN_EMAIL: `/signIn/email`,
}

export const signInRoutes: RouteConfig[] = [
    {
        path: signInRoutePaths.AUTH_LINK,
        component: () => import(/* webpackChunkName: "auth-link", webpackPrefetch: true */ '@/pages/signin/AuthLink.vue'),
        props: true,
        meta: { public: true },
    },
    {
        path: signInRoutePaths.SIGN_IN_PASSWORD,
        component: () => import(/* webpackChunkName: "sign-in-password", webpackPrefetch: true */ '@/pages/signin/SignInPassword.vue'),
        meta: { public: false },
    },
    {
        path: signInRoutePaths.SIGN_IN_EMAIL,
        component: () => import(/* webpackChunkName: "sign-in-email", webpackPrefetch: true */ '@/pages/signin/SignInEmail.vue'),
        meta: { public: false },
    },
]
