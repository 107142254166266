import { getCanAskForPushPermissionAgain, getPushAlreadyEnabled, isRunningInNativeApp } from '@/nativeWebInteraction/nativeWebInteraction'
import { sharedRoutePaths } from '@/routes/sharedRoutes'
import VueRouter from 'vue-router'
import { inspect, logger } from '@/utils/logger'
import { OnboardingRoute, onboardingRoutePaths } from '@/routes/onboardingRoutes'
import { appRoutePaths } from '@/routes/appRoutes'
import Vue from 'vue'

export const routeToNotificationIfPermsNotGranted = async (router: VueRouter, notificationPath: string, alternativePath: string) => {
    const pushAlreadyEnabled = await getPushAlreadyEnabled()
    // Default of true so we always ask for permission at least once during sign in or sign up on older devices.
    // In practice, this is on the more aggressive side, but it shouldn't really matter because it's only on
    // older native versions where a user has probably already signed up.
    const canAskForPushPermission = await getCanAskForPushPermissionAgain(true /* defaultValue */)
    const isNativeApp = isRunningInNativeApp()
    if (isNativeApp && !pushAlreadyEnabled && canAskForPushPermission) {
        logger.info(`Aven advisor is running in native app and push notification permissions are not yet requested. Routing to notification screen`)
        return await router.push({ path: notificationPath })
    }

    if (!isNativeApp) {
        logger.info(`Aven advisor is not running in native app. Routing to ${alternativePath} screen`)
    } else if (pushAlreadyEnabled) {
        logger.info(`Aven advisor already has push notif permission. Routing to ${alternativePath} screen`)
    } else {
        logger.info(`Aven advisor already has already asked for push notif permission and cannot ask again. Routing to ${alternativePath} screen`)
    }
    await router.push({ path: alternativePath })
}

export const maybeGetNotificationRouteIfPermsNotGranted = async (alternativePath: string): Promise<OnboardingRoute> => {
    const pushAlreadyEnabled = await getPushAlreadyEnabled()
    // Default of true so we always ask for permission at least once during sign in or sign up on older devices.
    // In practice, this is on the more aggressive side, but it shouldn't really matter because it's only on
    // older native versions where a user has probably already signed up.
    const canAskForPushPermission = await getCanAskForPushPermissionAgain(true /* defaultValue */)
    const isNativeApp = isRunningInNativeApp()
    if (isNativeApp && !pushAlreadyEnabled && canAskForPushPermission) {
        logger.info(`Aven advisor is running in native app and push notification permissions are not yet requested. Routing to notification screen`)
        return sharedRoutePaths.ENABLE_NOTIFICATION
    }

    if (!isNativeApp) {
        logger.info(`Aven advisor is not running in native app. Routing to ${alternativePath} screen`)
    } else if (pushAlreadyEnabled) {
        logger.info(`Aven advisor already has push notif permission. Routing to ${alternativePath} screen`)
    } else {
        logger.info(`Aven advisor already has already asked for push notif permission and cannot ask again. Routing to ${alternativePath} screen`)
    }
    return alternativePath
}

export const navigateToNotificationIfPermsNotGranted = async (router: VueRouter) => {
    const pushAlreadyEnabled = await getPushAlreadyEnabled()
    // Default of false so we don't prompt users on older native versions over and over on app start
    // even if they've denied permission
    const canAskForPushPermission = await getCanAskForPushPermissionAgain(false /* defaultValue */)
    if (!pushAlreadyEnabled && canAskForPushPermission) {
        logger.info(`Aven advisor is running in iOS native app and push notification permissions are not yet requested. Routing to notification screen`)
        await router.push(appRoutePaths.NOTIFICATION_PERMISSION)
    }
}

export const getMatchingOnboardingOrSharedPathOrThrow = (candidatePath: string | (string | null)[]): string => {
    const onboardingOnlyPaths = Object.values(onboardingRoutePaths)
    const sharedPaths = Object.values(sharedRoutePaths)
    const foundPath = [...onboardingOnlyPaths, ...sharedPaths].find((value) => {
        if (value === candidatePath) {
            return candidatePath
        }
    })
    if (foundPath) {
        return foundPath
    }
    throw Error(`No onboarding path found that matches ${candidatePath}`)
}

// Currently not being used, but keeping around in case we want to use it in the future
export const maybeGetProvePreFillAlternativeOnboardingRoutePath = (vue: Vue, currentRoutePath: OnboardingRoute): OnboardingRoute | null => {
    if (!vue.$store.getters.inProvePreFillTest) {
        logger.log(`Not in prove pre-fill test, returning null`)
        return null
    }

    logger.log(`In prove pre-fill test, getting potential alternative onboarding route path`)

    switch (currentRoutePath) {
        case sharedRoutePaths.ROOT:
            return sharedRoutePaths.OTP_VERIFICATION
        case sharedRoutePaths.ENABLE_NOTIFICATION:
            return onboardingRoutePaths.SET_PASSWORD
        case sharedRoutePaths.OTP_VERIFICATION:
            return onboardingRoutePaths.DATE_OF_BIRTH
        case onboardingRoutePaths.STATED_INCOME:
            // TODO if prove pre-fill successful, skip last 4 SSN
            return onboardingRoutePaths.SSN_LAST_4
        case onboardingRoutePaths.DATE_OF_BIRTH:
            // TODO if prove pre-fill successful, show combined full name and address
            return onboardingRoutePaths.FULL_NAME
        case onboardingRoutePaths.STATED_INCOME_IRS:
        case onboardingRoutePaths.KNOWLEDGE_BASED_AUTHENTICATION:
            return onboardingRoutePaths.SET_PASSWORD
        default:
            logger.error(`No alternative onboarding route path found for ${currentRoutePath}`)
            return null
    }
}

// As of 11/15/23, this is the default onboarding flow for all advisor users
export const getMethodFiPreFillOnboardingRoutePath = async (currentRoutePath: OnboardingRoute, vue: Vue): Promise<OnboardingRoute | null> => {
    logger.info(`getMethodFiPreFillOnboardingRoutePath -> vue: ${inspect(vue).slice(0, 2000)}`) // too long = crash
    switch (currentRoutePath) {
        case sharedRoutePaths.ROOT:
            return sharedRoutePaths.OTP_VERIFICATION
        case sharedRoutePaths.OTP_VERIFICATION:
            return onboardingRoutePaths.SSN_LAST_4
        case onboardingRoutePaths.SSN_LAST_4:
            return onboardingRoutePaths.PREFILLED_PERSONAL_INFO
        case onboardingRoutePaths.PREFILLED_PERSONAL_INFO:
            return onboardingRoutePaths.KNOWLEDGE_BASED_AUTHENTICATION
        case onboardingRoutePaths.KNOWLEDGE_BASED_AUTHENTICATION:
            return onboardingRoutePaths.STATED_INCOME_IRS
        case onboardingRoutePaths.STATED_INCOME_IRS:
            return await maybeGetNotificationRouteIfPermsNotGranted(onboardingRoutePaths.SET_PASSWORD_AUTH)
        case sharedRoutePaths.ENABLE_NOTIFICATION:
            return onboardingRoutePaths.SET_PASSWORD_AUTH
        default:
            logger.error(`No alternative onboarding route path found for ${currentRoutePath}`)
            return null
    }
}

export const getOnboardingRoutePath = async (currentRoutePath: OnboardingRoute, vue: Vue): Promise<OnboardingRoute | null> => {
    // Still keeping this layer for V2 onboarding
    return await getMethodFiPreFillOnboardingRoutePath(currentRoutePath, vue)
}
