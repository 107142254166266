"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SweepstakesType = exports.SweepstakesStatus = void 0;
var SweepstakesStatus;
(function (SweepstakesStatus) {
    SweepstakesStatus["OPEN"] = "OPEN";
    SweepstakesStatus["CLOSED"] = "CLOSED";
})(SweepstakesStatus = exports.SweepstakesStatus || (exports.SweepstakesStatus = {}));
var SweepstakesType;
(function (SweepstakesType) {
    SweepstakesType["CASH"] = "CASH";
    SweepstakesType["MORTGAGE"] = "MORTGAGE";
    SweepstakesType["PLAID_TRANSACTION"] = "PLAID_TRANSACTION";
})(SweepstakesType = exports.SweepstakesType || (exports.SweepstakesType = {}));
