<template>
    <div
        id="app"
        class="app-container"
    >
        <!-- This is needed to preserve Today page after user navigated away -->
        <transition mode="out-in">
            <keep-alive :include="keepAliveIncludes">
                <router-view
                    v-keep-scroll-position
                    :key="$route.path"
                    class="responsive-dashboard"
                />
            </keep-alive>
        </transition>
        <div
            id="modalBackdrop"
            class="modal-backdrop"
            style="display: none"
        />
    </div>
</template>

<script>
    import Vue from 'vue'
    import { logger } from '@/utils/logger'
    import { i18n } from './utils/i18n'
    import { logUrlProperties } from './services/marketing'
    import { runWithRetryLogic } from '@/utils/http-client'
    import { isRunningInNativeApp } from '@/nativeWebInteraction/nativeWebInteraction'
    import { initChartJs } from '@/utils/initChartJs'
    import { mapFields } from 'vuex-map-fields'
    import { SignUpGoal } from 'aven_types/dist/advisor/avenAdvisorAccountInfo'

    const source = {
        best_heloc: 'best_heloc',
        best_credit_card: 'best_credit_card',
    }

    const sourceToSignUpGoalMap = {
        [source.best_heloc]: SignUpGoal.HELOC,
        [source.best_credit_card]: SignUpGoal.CREDIT_CARD,
    }

    export default Vue.extend({
        name: 'App',
        computed: {
            ...mapFields(['persistedStore']),
            isLoggedIn: function () {
                return !!this.persistedStore.jwtTokens
            },
            keepAliveIncludes: function () {
                return this.isLoggedIn ? ['Dashboard'] : []
            },
        },
        created() {
            // Set Locale for Language
            let locale = 'en'
            if (navigator.userLanguage) {
                locale = navigator.userLanguage.includes('es') ? 'es' : 'en'
            } else if (navigator.language) {
                locale = navigator.language.includes('es') ? 'es' : 'en'
            } else {
                logger.info('Unable to determine user language from javascript, defaulting to "en"')
            }
            i18n.locale = locale
        },
        mounted() {
            runWithRetryLogic(async () => {
                await logUrlProperties()
            }, 2)

            if (isRunningInNativeApp()) {
                document.body.style['-webkit-touch-callout'] = 'none'
                document.body.style['-webkit-user-select'] = 'none'
                document.body.style['-khtml-user-select'] = 'none'
                document.body.style['-moz-user-selec'] = 'none'
                document.body.style['-ms-user-select'] = 'none'
                document.body.style['user-select'] = 'none'
            }

            this.loadGoogleMapsJavaScriptAPI()

            const queryParams = new URLSearchParams(window.location.search)
            const source = queryParams.get('source')
            if (source) {
                logger.log(`Got source ${source} from query params`)
                const signUpGoal = sourceToSignUpGoalMap[source]
                logger.log(`Setting sign up goal to ${signUpGoal}`)
                this.$store.commit('setSignUpGoal', signUpGoal)
            }

            initChartJs()
        },
        methods: {
            loadGoogleMapsJavaScriptAPI() {
                ((g) => {
                    let h,
                        a,
                        k,
                        p = 'The Google Maps JavaScript API',
                        c = 'google',
                        l = 'importLibrary',
                        q = '__ib__',
                        m = document,
                        b = window
                    b = b[c] || (b[c] = {})
                    const d = b.maps || (b.maps = {}),
                          r = new Set(),
                          e = new URLSearchParams(),
                          u = () =>
                              h ||
                              // eslint-disable-next-line
                            (h = new Promise(async (f, n) => {
                                  await (a = m.createElement('script'))
                                  e.set('libraries', [...r] + '')
                                  for (k in g)
                                      e.set(
                                          k.replace(/[A-Z]/g, (t) => '_' + t[0].toLowerCase()),
                                          g[k]
                                      )
                                  e.set('callback', c + '.maps.' + q)
                                  a.src = `https://maps.${c}apis.com/maps/api/js?` + e
                                  d[q] = f
                                  a.onerror = () => (h = n(Error(p + ' could not load.')))
                                  a.nonce = m.querySelector('script[nonce]')?.nonce || ''
                                  m.head.append(a)
                              }))
                    d[l] ? console.warn(p + ' only loads once. Ignoring:', g) : (d[l] = (f, ...n) => r.add(f) && u().then(() => d[l](f, ...n)))
                })({
                    key: 'AIzaSyAGWhF2YlVudG9hsO7ekz8tqiPr-i2vvG4',
                })
            },
        },
    })
</script>

<style lang="scss">
    @import '@/styles/tailwind.scss';
    @import 'node_modules/aven_shared/src/styles/main.scss';
    @import 'node_modules/aven_shared/src/styles/components/responsiveDashboard';

    html,
    body {
        margin: 0;
        height: 100%;
        overflow: hidden;
    }

    @supports (-webkit-touch-callout: none) {
        .app-container {
            // Fixes issue on iOS that causes the bottom navigation bar to be hidden underneath the safari search bar
            height: -webkit-fill-available;
        }
    }
</style>
