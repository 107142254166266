import { inspect, logger } from '@/utils/logger'
import { requestPlaidLinkToken, plaidReportFetchState, startPlaidReportFetch, requestPlaidLinkUpdateToken } from '@/services/api'
import assert from 'assert'
import store from '@/store'

type OnPlaidSuccess = (plaidPublicToken: string, accounts: object[], institutionInfo: object | any) => {}
type PlaidLinkError = {
    error_type: string
    error_code: string
    error_message: string
    display_message: string
}
type PlaidLinkOnExitMetadata = {
    institution: null | {
        name: string
        institution_id: string
    }
    // see possible values for status at https://plaid.com/docs/link/web/#link-web-onexit-status
    status: null | string
    link_session_id: string
    request_id: string
}
type PlaidLinkOnExit = (error: null | PlaidLinkError, metadata: PlaidLinkOnExitMetadata) => void

type PlaidHandler = {
    error: ErrorEvent | null
    ready: boolean
    exit: Function
    open: Function
    // This one is psuedo-documented
    // https://plaid.com/docs/link/web/#destroy
    destroy: Function
}

const PLAID_SRC = 'https://cdn.plaid.com/link/v2/stable/link-initialize.js'

export class PlaidManager {
    private plaidHandler?: PlaidHandler
    private readonly onPlaidSuccess: OnPlaidSuccess
    private readonly onPlaidExit: PlaidLinkOnExit
    private updateMode: boolean = false
    private institutionId: string | null = null

    constructor(onPlaidSuccess: OnPlaidSuccess, onPlaidExit: PlaidLinkOnExit) {
        this.onPlaidSuccess = onPlaidSuccess
        this.onPlaidExit = onPlaidExit
    }

    public init = async (updateMode: boolean = false, institutionId: string | null = null): Promise<boolean> => {
        logger.info(`bankConnect headless`)
        // Load the Plaid script async
        this.updateMode = updateMode
        this.institutionId = institutionId
        await this.loadPlaidScript()
        return await this.setupPlaid()
    }

    private loadPlaidScript = async (): Promise<void> => {
        for (const x of document.head.getElementsByTagName('script')) {
            if (x.src === PLAID_SRC) {
                logger.info('Plaid already loaded')
                return
            }
        }
        logger.info('Plaid script not found in DOM. Attaching...')

        return new Promise((resolve) => {
            const script = document.createElement('script')
            script.onload = () => {
                logger.info('Plaid script loaded')
                resolve()
            }
            script.setAttribute('src', PLAID_SRC)
            logger.info('Waiting for Plaid script to load...')
            document.head.appendChild(script)
        })
    }

    private getLinkToken = async (fromCache: boolean): Promise<string | undefined> => {
        try {
            if (fromCache) {
                return await store.dispatch('getPlaidLinkToken')
            } else {
                let linkToken: string | undefined
                if (this.updateMode && this.institutionId) {
                    const response = await requestPlaidLinkUpdateToken(this.institutionId)
                    linkToken = response.data.payload.linkUpdateToken
                } else {
                    const response = await requestPlaidLinkToken()
                    linkToken = response.data.payload.linkToken
                }
                await store.dispatch('setPlaidLinkToken', linkToken)
                return linkToken
            }
        } catch (error) {
            logger.fatal('Failed to fetch link token', error)
        }
    }

    private setupPlaid = async (): Promise<boolean> => {
        try {
            let linkToken = await this.getLinkToken(true)
            const oauthStateId = this.getHasOAuthStateId()
            // We aren't allowed to carry extra query parameters with us into and out of the OAuth flow
            this.removeNonOauthQueryParams()
            if (linkToken && oauthStateId) {
                // This state happens when the user is redirected to the app having completed the oauth flow externally
                this.plaidHandler = this.createPlaidHandler(linkToken, this.onPlaidSuccess, this.onPlaidExit)
                this.plaidHandler.open()
            } else if (linkToken && !oauthStateId) {
                // This can occur if a user begins but fails out of the OAuth flow for one reason or another
                logger.info(`Detected linkToken in sessionStorage (${linkToken}) but without an associated oauth_state_id, nuking linkToken from storage and getting a fresh link token`)
                await store.dispatch('setPlaidLinkToken', null)
                linkToken = await this.getLinkToken(false)
                assert(linkToken, 'Should have a linkToken returned from getLinkToken()!')
                this.plaidHandler = this.createPlaidHandler(linkToken, this.onPlaidSuccess, this.onPlaidExit)
            } else if (!linkToken && !oauthStateId) {
                // Initial Plaid link state
                linkToken = await this.getLinkToken(false)
                assert(linkToken, 'Should have a linkToken returned from getLinkToken()!')
                this.plaidHandler = this.createPlaidHandler(linkToken, this.onPlaidSuccess, this.onPlaidExit)
            } else {
                // !linkToken && oauthStateId
                this.removeAllQueryParams()
                logger.fatal(`Detected an oauth state id in the query parameters without a cached link token! Check implementation.`)
                linkToken = await this.getLinkToken(false)
                assert(linkToken, 'Should have a linkToken returned from getLinkToken()!')
                this.plaidHandler = this.createPlaidHandler(linkToken, this.onPlaidSuccess, this.onPlaidExit)
            }

            return true
        } catch (error) {
            logger.fatal('Plaid initialize failed error:', error)
        }
        return false
    }

    public open = (): void => {
        logger.info('Opening plaid...')
        this.plaidHandler?.open()
    }

    public exit = (): void => {
        logger.info('Making sure to exit plaid if its open...')
        this.plaidHandler?.exit()
    }

    private getHasOAuthStateId = (): boolean => {
        const queryParams = new URLSearchParams(window.location.search)
        return queryParams.has('oauth_state_id')
    }

    private removeNonOauthQueryParams = () => {
        const queryParams = new URLSearchParams(window.location.search)
        for (const [key] of queryParams) {
            if (key !== 'oauth_state_id') {
                queryParams.delete(key)
            }
        }

        window.history.replaceState(null, '', `${window.location.pathname}?${queryParams.toString()}`)
    }

    private removeAllQueryParams = () => {
        window.history.replaceState(null, '', window.location.pathname)
    }

    private cleanUpExistingPlaidIFrames = () => {
        // Always nuke any existing instances that are sticking around
        this.plaidHandler?.destroy()
        // This is necessary b/c sometimes plaid doesn't seem to delete itself fully (and it breaks various cypress tests)
        const elements = document.getElementsByTagName('iframe')
        // It's necessary to cache elements.length b/c it will reduce in size as we delete HTML elements from it
        for (let i = 0, len = elements.length; i != len; ++i) {
            elements[0].parentNode?.removeChild(elements[0])
        }
    }

    private createPlaidHandler = (linkToken: string, onSuccessHandler: OnPlaidSuccess, onExitHandler: PlaidLinkOnExit): PlaidHandler => {
        this.cleanUpExistingPlaidIFrames()

        // Our OAuth flow will automagically redirect us back to the same page we're on
        // Once redirected, we will need to pass our new query param ('oauth_state_id') back to the Plaid SDK
        const hasOAuthStateId = this.getHasOAuthStateId()

        const options = {
            token: linkToken,
            receivedRedirectUri: hasOAuthStateId ? window.location.href : undefined,
            onEvent: function (eventName: string, metadata: object) {
                logger.info(`plaid link onEvent metaData: ${JSON.stringify(metadata)}`)
                // log all events provided by plaid. note that these won't trigger until the plaid screen is closed.
                const internalEventName = `plaid_internal_${eventName.toLowerCase()}`
                window.logEvent(internalEventName, metadata)
            },
            onSuccess: function (publicToken: string, metadata: any) {
                window.logEvent('plaid_on_success', { ['plaid_bank_name']: metadata.institution.name })
                logger.info(`plaid link onSuccess metaData: ${JSON.stringify(metadata)}`)
                onSuccessHandler(publicToken, metadata.accounts, metadata.institution)
            },
            onExit: (error: PlaidLinkError | null, metadata: PlaidLinkOnExitMetadata) => {
                if (error) {
                    logger.info(`plaid link onExit returned error: ${JSON.stringify(error)}`)
                    window.logEvent('plaid_internal_error', { ...error, ...metadata })
                }

                logger.info(`plaid link onExit metaData: ${JSON.stringify(metadata)}`)

                // Always clear out the OAuth query parameters if there's an error, otherwise user will get stuck in a loop
                this.removeAllQueryParams()

                onExitHandler(error, metadata)
            },
        }

        logger.info(`Loading plaid link with the following options: ${inspect(options)}`)

        // This is a slight difference between the plaid-link-react and plaid-javascript libraries (typing wise)
        return window.Plaid.create(options) as unknown as PlaidHandler
    }

    public static completePlaidFetch = async (plaidPublicToken: string, institutionId: string) => {
        let itemId: string | undefined
        try {
            const response = await startPlaidReportFetch(plaidPublicToken, institutionId)
            logger.info(`PlaidReport fetch started with response: ${inspect(response)}`)
            itemId = response.data.payload.itemId
        } catch (error) {
            logger.fatal(`Failed to start plaid report fetch`, error)
        }
        return itemId
    }

    public static pollForPlaidReportCompletion = async (itemId: string): Promise<boolean> => {
        let retryCount = 0
        logger.info(`Beginning to poll for plaidReport fetch completion with itemId: ${itemId}`)
        while (retryCount < 150) {
            // ~ 2000ms * 150 retries = poll for 5 min
            const plaidReportState = await plaidReportFetchState(itemId)

            if (!plaidReportState.data.payload.isFetched) {
                retryCount++
                logger.info(`PlaidReport NOT READY, attempt#: ${retryCount}`)
                await new Promise((r) => setTimeout(r, 2_000))
                continue
            }
            logger.info(`PlaidReport fetch is complete!`)

            // Plaid Asset Fetch Status is set to StatusCodes.OK.toString() only if the asset report
            // fetch was successful.
            return plaidReportState.data.payload.isFetched
        }
        logger.fatal(`PlaidReport fetch did not appear to complete successfully!`)
        return false
    }
}
