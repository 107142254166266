"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AvenAdvisorNumberOfBedrooms = exports.SignUpGoal = exports.MaritalStatus = void 0;
var MaritalStatus;
(function (MaritalStatus) {
    MaritalStatus["MARRIED"] = "MARRIED";
    MaritalStatus["UNMARRIED"] = "UNMARRIED";
    MaritalStatus["SEPARATED"] = "SEPARATED";
})(MaritalStatus = exports.MaritalStatus || (exports.MaritalStatus = {}));
var SignUpGoal;
(function (SignUpGoal) {
    SignUpGoal["HELOC"] = "HELOC";
    SignUpGoal["CREDIT_CARD"] = "CREDIT_CARD";
})(SignUpGoal = exports.SignUpGoal || (exports.SignUpGoal = {}));
var AvenAdvisorNumberOfBedrooms;
(function (AvenAdvisorNumberOfBedrooms) {
    AvenAdvisorNumberOfBedrooms["ONE"] = "1";
    AvenAdvisorNumberOfBedrooms["TWO"] = "2";
    AvenAdvisorNumberOfBedrooms["THREE"] = "3";
    AvenAdvisorNumberOfBedrooms["FOUR_PLUS"] = "4+";
})(AvenAdvisorNumberOfBedrooms = exports.AvenAdvisorNumberOfBedrooms || (exports.AvenAdvisorNumberOfBedrooms = {}));
